.products-pagination{

  nav{
    .pagination{
      .page-item{
        @include font-size(14px);

      }
    }
  }
  .disabled{
    opacity: 0;
    @include sm(){
      opacity: 1;
      display: none;
    }
  }
}

.pagination{
  gap: 8px;
  flex-wrap: wrap;
}


.page-item{

  text-align: center;

  .page-link:hover{

    border-radius: 4px;
    background-color:  #F9FAFB;
    color: #374151;
    box-shadow: none;


  }

  .page-link:active ,.page-link.active{
    border-radius: 4px !important;
    background-color: #D6DCF2 !important;
    color: #253786 !important;
    box-shadow: none;


  }


  .page-link:focus{

    background-color:  #D6DCF2;
    color: #253786 !important;
    border-radius: 4px;
    box-shadow: none;

  }

  .page-link:visited{

    box-shadow: none;

  }

  &:not(:first-child){
    margin-left: 0 !important;
  }

  &:not(:first-child):not(:last-child) {
    width: 36px;
    height: 36px;
  }


  &:last-child, &:first-child{

    .page-link{
      width: unset;
    }

    .page-link:hover, .page-link:active ,.page-link.active{
      background-color:  transparent !important;
      color: #253786!important;

      i{
        color: #253786 !important;
      }
    }

    .page-link:focus{
      background-color:  unset;
      color: unset;
      border-radius: 4px;
    }

    &.disabled{
      display: none;
    }
  }
}

#products-list{

  .products-block{
    display: none;

    &.active{
     display: flex;
    }
  }

}
