.orders{
  margin-top: 0 !important;
  margin-bottom: 640px;
  @include font-roboto();

  @include md(){
    margin-top: 24px!important;
  }

  &--header{

    h2{
      font-weight: 700;
      font-size: 28px;
      line-height: 36px;
      display: flex;
      align-items: center;
      letter-spacing: -0.02em;
      color: #111827;
      flex: none;
      order: 0;
      flex-grow: 0;
    }

    p{
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #6B7280;
      flex: none;
      order: 0;
      flex-grow: 0;
      width: 100%;
      flex-direction: row;
      padding: 10px 0;
      gap: 8px;
    }

    .btn{
      display: none;
    }



    &--no-orders{

      h2{
        font-weight: 700;
        font-size: 28px;
        line-height: 36px;
        display: flex;
        align-items: center;
        letter-spacing: -0.02em;
        color: #111827;
        flex: none;
        order: 0;
        flex-grow: 0;
      }

      p{
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        align-items: center;
        color: #6B7280;
        flex: none;
        order: 0;
        flex-grow: 0;
        background: #F9FAFB;
        border-radius: 8px;
        width: 100%;
        flex-direction: row;
        padding: 10px 16px;
        gap: 8px;
      }

      .btn{
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: #FFFFFF;
        flex: none;
        order: 0;
        flex-grow: 0;
        padding: 12px 20px;
      }

    }

  }

  &--list{

    .table{
      width: 100%;
      border-collapse:separate;
      border-spacing:0 15px;
      display: flex;
      flex-direction: column;

      .thead{
        font-weight: 700;
        font-size: rem(14px);
        line-height: 20px;
        display: flex;
        flex-direction: column;

        @include md(){
          display: none;
        }

        .tr{
          padding-top: 24px;
          padding-bottom: 16px;
          height: 60px;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;

          .td{
            width: 20%;
            display: flex;
            align-items: center;

            &:nth-child(4){
              text-align: right;
            }
          }
        }

      }

      .tbody{
        display: flex;
        flex-direction: column;

        .tr{
          height: 56px;
          box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.12);
          border-radius: 8px;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          align-items: center;
          margin-top: 16px;

          @include md(){
            height: auto;
            padding-top: 12px;
            padding-bottom: 12px;
          }

          .td{
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: $color-grey;
            height: inherit;
            width: 20%;
            display: flex;
            align-items: center;

            @include md(){
              width: 50%;
              padding-left: 16px;

            }

            &:nth-child(1){
              font-weight: 700;
              padding-left: 16px;

              @include md(){
                order: 1;
              }
            }

            &:nth-child(2){

              @include md(){
                order: 3;
              }
            }

            &:nth-child(3){
              &.succeed{
                color: #22C55E;
              }
              &.error{
                color: rgba(185, 20, 20, 0.88);
              }

              @include md(){
                order: 2;
                justify-content: end;
                padding-right: 12px;
              }
            }

            &:nth-child(4){
              font-weight: 700;
              text-align: right;

              @include md(){
                order: 4;
                justify-content: end;
                padding-right: 12px;
              }
            }

            &:nth-child(5){
              padding-right: 16px;
              display: flex;
              justify-content: center;
              align-items: center;

              @include md(){
                padding-right: 12px;
                order: 5;
                justify-content: start;
              }
            }

            .btn{
              font-weight: 400;
              font-size: 14px !important;
              line-height: 16px !important;
              text-align: center;
              color: #374151;
              box-sizing: border-box;
              display: flex;
              flex-direction: row;
              justify-content: end;
              align-items: center;
              padding: 6px 10px !important;
              gap: 8px;
              width: 134px;
              height: 28px;
              background: #FFFFFF;
              border: 1px solid #D1D5DB;
              border-radius: 4px;


            }
          }
        }
      }

    }


    &--no-orders{
      display: none;
    }

  }

}

.order-detail{
  margin-top: 0 !important;
  margin-bottom: 640px;
  @include font-roboto();

  @include md(){
    margin-top: 24px!important;
    margin-bottom: 40px;
  }


  &--header{

    h2{
      font-weight: 700;
      font-size: 28px;
      line-height: 36px;
      display: flex;
      align-items: center;
      letter-spacing: -0.02em;
      color: #111827;
      flex: none;
      order: 0;
      flex-grow: 0;
    }

    p{
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #6B7280;
      flex: none;
      order: 0;
      flex-grow: 0;
      width: 100%;
      flex-direction: row;
      padding: 10px 0;
      gap: 8px;
    }
  }

  &--details{
    padding-top: 24px;
    margin-bottom: 40px;
    p{
      margin: 0;
      padding: 0;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #374151;

      &.order-detail--details--title{
        font-weight: 700;
        margin-bottom: 16px;
      }
    }



  }

  &--table{
    h3{
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      display: flex;
      align-items: center;
      letter-spacing: -0.02em;
      color: #111827;
    }

    table{
      width: 100%;
      thead{
        font-weight: 700;
        font-size: rem(14px);
        line-height: 20px;
        tr{
          padding-top: 24px;
          padding-bottom: 16px;
          height: 60px;
          td{
            width: 196px;

            &:nth-child(2){
              width: 492px;
            }

            &:nth-child(3){
              width: 92px;
            }

            &:nth-child(4){
              text-align: right;
            }
          }
        }

      }

      tbody{
        tr{
          height: 36px;

          td{
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: $color-grey;
            height: inherit;

            &:nth-child(4){
              text-align: right;
              font-weight: 700;
            }
          }
        }
      }

    }
  }

  .orders-recap-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;

    .orders-recap-row{
      display: flex;
      flex-direction: row;
      justify-content: space-between;

    }
  }

}

.settings{
  margin-top: 0 !important;
  @include font-roboto();

  @include md(){
    margin-top: 24px!important;
  }


  &--header{

    h2{
      font-weight: 700;
      font-size: 28px;
      line-height: 36px;
      display: flex;
      align-items: center;
      letter-spacing: -0.02em;
      color: #111827;
      flex: none;
      order: 0;
      flex-grow: 0;
    }

    p{
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #374151;
      flex: none;
      order: 1;
      align-self: stretch;
      flex-grow: 0;
    }
  }

  &--forms{
    margin-top: 24px;

    &--item{
      padding: 12px 0;
      h3{
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        display: flex;
        align-items: center;
        letter-spacing: -0.02em;
        color: #111827;
        flex: none;
        order: 0;
        align-self: stretch;
      }

      label{
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #111827;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin-top: 16px;
      }

      input[type="text"],input[type="email"],input[type="password"],input[type="number"]{
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 12px;
        gap: 8px;
        width: 100%;
        height: 44px;
        background: #FFFFFF;
        border: 1px solid #D1D5DB;
        border-radius: 4px;
        flex: none;
        order: 1;
        align-self: stretch;
        flex-grow: 0;
      }

      input[type=number]::-webkit-inner-spin-button,
      input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }

    &--submit-row{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-top: 24px;
      label{
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: #374151;
        flex: none;
        order: 1;
        flex-grow: 0;
      }

      .btn{
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        padding: 12px 20px;
        color: #FFFFFF;
      }
    }
  }

  .settings-small-text{
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #374151;
    flex: none;
    order: 3;
    align-self: stretch;
    flex-grow: 0;
    margin-top: 24px;
  }
}