.login{
  width: 568px;
  margin: 0 auto 196px auto;
  @include font-roboto();
  font-style: normal;

  @include breakpoint($screen-sm){
    width: 100%;
    margin: 0 auto 40px auto;
  }

  &--title-wrapper{
    text-align: center;

    h2{
      font-weight: 700;
      font-size: 28px;
      line-height: 36px;
      letter-spacing: -0.02em;
      color: #111827;
      flex: none;
      order: 0;
      align-self: stretch;
      flex-grow: 0;
    }

    p{
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #374151;
      flex: none;
      order: 1;
      align-self: stretch;
      flex-grow: 0;
    }
  }

  &--form-wrapper{
    label{
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #111827;
      flex: none;
      order: 0;
      flex-grow: 0;
      margin-top: 16px;
    }

    input[type="email"],input[type="password"]{
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 12px;
      gap: 8px;
      width: 100%;
      height: 44px;
      background: #FFFFFF;
      border: 1px solid #D1D5DB;
      border-radius: 4px;
      flex: none;
      order: 1;
      align-self: stretch;
      flex-grow: 0;
    }

    &--forgot-pass-row{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;


      @include breakpoint($screen-fold){
        flex-direction: column;
      }

      a{
        text-decoration: none;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: #374151;
        flex: none;
        order: 0;
        flex-grow: 0;
      }

      .btn{
        @include breakpoint($screen-fold){
          margin-top: 40px
        }
      }

    }
  }

  &--texts-wrapper{


    .btn{
      margin: 0 auto;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 12px 20px;
      gap: 8px;
      width: 157px;
      height: 44px;
      background: #FFFFFF;
      border: 1px solid #D1D5DB;
      border-radius: 4px;
      flex: none;
      order: 3;
      flex-grow: 0;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #374151;
    }
  }
}
