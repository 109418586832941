.suppliers-header{

  padding: 84px 40px;

  &--title{
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: -0.02em;
    color: #253786;

  }

  &--text{
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #374151;
    width: 620px;
  }
}

.suppliers-list{
  &.row{
    --bs-gutter-x: 12px;
  }
}

.pi--supplier-item{
  border-radius: 4px;
  width: 172px;
  height: 64px;
  border: 1px solid #D1D5DB;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 12px;
  vertical-align: middle;
  text-decoration: none;
  justify-content: space-between;
  color: $color-grey;
  margin-bottom: 16px;

  @include md(){
    margin-bottom: 12px;
  }

  &:hover{
    border: 1px solid #D1D5DB;
    background: #F9FAFB;
    color: $color-grey;
  }

  &.active, &:active{
    border: 1px solid #253786;
    background: #fff;
    color: #253786;
  }

  @include md(){
    width: 100%;
  }

  i{

    vertical-align: middle;
  }
}