.cart-steps-final {
  margin-top: rem(24px);

  .cart-contact-recap{

    &--row{

      &:nth-child(2){
        margin-top: 40px;
        margin-bottom: 40px;
      }

      h3{
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        display: flex;
        align-items: center;
        letter-spacing: -0.02em;
        color: #111827;
        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;
      }

      p{
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #374151;
        flex: none;
        order: 0;
        flex-grow: 1;
        margin: 0;
        padding: 0;

        &.delivery-type-title{
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          display: flex;
          align-items: center;
          color: #111827;
          flex: none;
          order: 0;
          align-self: stretch;
          flex-grow: 0;
        }

        &.delivery-type-description{
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          display: flex;
          align-items: center;
          color: #374151;
          flex: none;
          order: 1;
          align-self: stretch;
          flex-grow: 0;
        }
      }
    }

  }

  .cart-products-recap{

    &--row{
      h3{
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: -0.02em;
        color: #111827;
        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;
      }

      &--products-list{
        margin-top: 16px;
        display: flex;
        flex-direction: column;

        &--item{
          display: flex;
          flex-wrap: wrap;
          flex-direction: row;
          justify-content: start;

          & + .cart-products-recap--row--products-list--item {
            margin-top: 16px!important;

            @include xs {
              margin-top: 24px!important;
            }
          }


          &--image{
            margin-right: 16px;

            img {
              max-width: 60px;
              max-height: 60px;
              width: auto;
              height: auto;
            }

            @include md {
              display: flex;
              align-items: center;
            }
          }

          &--description{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            flex: 0 1 calc(100% - 80px);
            //align-items: baseline;

            .product-name{
              font-weight: 700;
              font-size: 16px;
              line-height: 24px;
              color: #111827;
              margin: 0;
              padding: 0;
            }

            .product-count{
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              color: #374151;
              margin: 0;
              padding: 0;

              @include md {
                margin-top: rem(4px);
              }
            }
          }

          &--title {
            @include md {
              flex: 0 1 100%;
            }
          }

          &--prices{
            color: $color-grey;
            text-align: right;
            display: block;

            @include md {
              margin-top: rem(4px);
              flex: 0 1 100%;
              text-align: left;
            }

            .price-w-taxes{
              font-weight: 700;
              font-size: 16px;
              line-height: 24px;
              color: #111827;
              margin: 0;
              padding: 0;
              display: block;
            }
            .price-no-taxes{
              font-weight: 400;
              font-size: 12px;
              line-height: 20px;
              margin: 0;
              padding: 0;
              display: block;
            }
          }
        }
      }

      &--prices-recap{
        display: flex;
        flex-direction: column;

        &--row{
          display: flex;
          flex-direction: row;
          margin: 0;
          padding: 0;

          .price-recap-title{
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            color: #111827;
            flex: none;
            order: 0;
            flex-grow: 0;
            margin: 0;
            padding: 0;
          }

          .price-recap-value{
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            text-align: right;
            color: #374151;
            flex: none;
            order: 1;
            flex-grow: 0;
            margin: 0;
            padding: 0;

            @include xs {
              text-align: left;
            }

            &--final{
              font-weight: 700;
              text-align: right;
              margin: 0;
              padding: 0;

              @include xs {
                text-align: left;
              }
            }
          }
        }
      }

      textarea{
        resize: none;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 12px;
        gap: 8px;
        width: 100%;
        height: 160px;
        background: #FFFFFF;
        border: 1px solid #D1D5DB;
        border-radius: 4px;
        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 1;
      }
      .form-check{
        margin-top: 40px;
      }
    }

  }
}