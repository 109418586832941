.actuality-wrapper{
  display: flex;
  flex-direction: row;
  align-items: stretch;
  min-height: 274px;

  @include md(){
    flex-direction: column;
  }

  &:last-child{
    margin-bottom: 64px !important;
  }

  .actuality-image-part{
    width: 100%;
    max-width: 274px;
    border-radius: 8px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    align-items: center;

    @include sm(){
      max-width: 142px;
      max-height: 142px;
      align-items: start;
    }


    img{
      margin: auto auto;
      display: block;
      vertical-align: middle;

      @include sm(){
        max-width: 142px;
        max-height: 142px;
        height: auto;
      }
    }
  }

  .actuality-text-part{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 20px;

    @include sm(){
      flex-direction: column;
      padding:16px 12px;
    }

    .actuality-links{
      width: 100%;
      max-width: 274px;
      display: flex;
      flex-direction: row;
      order: 1;
      align-self: stretch;
      flex-grow: 0;
      justify-content: space-between;

      @include md(){
        flex-wrap: wrap;
      }

      .btn{
        margin-right: 20px;

        @include md(){
          margin-right: 0;
        }


      }

      .link-download{
        @include font-roboto();
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: $color-grey;
        flex: none;
        order: 1;
        flex-grow: 0;
        text-decoration: none;
        align-items: center;
        display: flex;

        @include breakpoint($screen-fold){
          margin-top: 16px;
        }

        i{
          padding-right: 8px;
        }
      }

    }
  }
}