body{
  /*font-size: 100%;
  position: relative;
  overflow-x: hidden;
  width: 100%;
  background: darken($color-drakula, 7);
  color: black;
  @include font-regular;*/
  font-family: 'Roboto', sans-serif;
  font-size: 100%;
  line-height: 1.5;
  overflow-x: hidden;
}

.clearfix {
  &:after{
    content: "";
    clear: both;
    display: table;
  }
}

.main-body {
  margin-top: rem(163px);
}


input[type="checkbox"]{
  border: 1px solid #6B7280;
  border-radius: 4px !important;
  width: 16px!important;
  height: 16px!important;
  &:checked{
    background: #253786;
  }
}


.hide-mobile{
  @include md(){
    display: none;
  }
}

.mlr-auto{
  margin-left: auto!important;
  margin-right: auto!important;
}

.clearpad{
  padding: unset!important;
  margin: unset!important;
}

/* MARGINS */
/* MARGIN TOP */
.mt{
  /* 8px */
  &-8{
    margin-top: 8px!important;
  }

  &-sm-8{
    @include sm(){
      margin-top: 8px!important;
    }
  }

  &-md-8{
    @include md(){
      margin-top: 8px!important;
    }
  }
  /* **** */
  /* 12px */
  &-12{
    margin-top: 12px!important;
  }

  &-sm-12{
    @include sm(){
      margin-top: 12px!important;
    }
  }

  &-md-12{
    @include md(){
      margin-top: 12px!important;
    }
  }
  /* **** */
  /* 16px */
  &-16{
    margin-top: 16px!important;
  }

  &-sm-16{
    @include sm(){
      margin-top: 16px!important;
    }
  }

  &-md-16{
    @include md(){
      margin-top: 16px!important;
    }
  }
  /* **** */
  /* 20px */
  &-20{
    margin-top: 20px!important;
  }

  &-sm-20{
    @include sm(){
      margin-top: 20px!important;
    }
  }

  &-md-20{
    @include md(){
      margin-top: 20px!important;
    }
  }
  /* **** */
  /* 24px */
  &-24{
    margin-top: 24px!important;
  }

  &-sm-24{
    @include sm(){
      margin-top: 24px!important;
    }
  }

  &-md-24{
    @include md(){
      margin-top: 24px!important;
    }
  }
  /* **** */
  /* 40px */
  &-40{
    margin-top: 40px;
/*
    @include md(){
      margin-top: 24px !important;
    }*/
  }

  &-sm-40{
    @include sm(){
      margin-top: 40px!important;
    }
  }

  &-md-40{
    @include md(){
      margin-top: 40px!important;
    }
  }
  /* **** */
  /* 64px */
  &-64{
    margin-top: 64px;

    @include md(){
      margin-top: 40px;
    }
  }

  &-sm-64{
    @include sm(){
      margin-top: 64px!important;
    }
  }

  &-md-64{
    @include md(){
      margin-top: 64px!important;
    }
  }
  /* **** */

  &-40-md-24{
    margin-top: 40px !important;

    @include md(){
      margin-top: 24px !important;;
    }
  }
}
/* MARGIN TOP END */
/* MARGIN BOTTOM */
.mb{

  /* CUSTOM */

  &-24-md-12{
    margin-bottom: 24px;

    @include md(){
      margin-bottom: 12px;
    }
  }

  /* 8px */
  &-8{
    margin-bottom: 8px!important;
    @include sm(){
      margin-bottom: unset;
    }
  }

  &-sm-8{
    @include sm(){
      margin-bottom: 8px!important;
    }
  }

  &-md-8{
    @include md(){
      margin-bottom: 8px!important;
    }
  }
  /* **** */
  /* 12px */
  &-12{
    margin-bottom: 12px!important;
  }

  &-sm-12{
    @include sm(){
      margin-bottom: 12px!important;
    }
  }

  &-md-12{
    @include md(){
      margin-bottom: 12px!important;
    }
  }
  /* **** */
  /* 16px */
  &-16{
    margin-bottom: 16px!important;
  }

  &-sm-16{
    @include sm(){
      margin-bottom: 16px!important;
    }
  }

  &-md-16{
    @include md(){
      margin-bottom: 16px!important;
    }
  }
  /* **** */
  /* 20px */
  &-20{
    margin-bottom: 20px!important;
  }

  &-sm-20{
    @include sm(){
      margin-bottom: 20px!important;
    }
  }

  &-md-20{
    @include md(){
      margin-bottom: 20px!important;
    }
  }
  /* **** */
  /* 24px */
  &-24{
    margin-bottom: 24px!important;
  }

  &-sm-24{
    @include sm(){
      margin-bottom: 24px!important;
    }
  }

  &-md-24{
    @include md(){
      margin-bottom: 24px!important;
    }
  }
  /* **** */
  /* 40px */
  &-40{
    margin-bottom: 40px!important;
  }

  &-sm-40{
    @include sm(){
      margin-bottom: 40px!important;
    }
  }

  &-md-40{
    @include md(){
      margin-bottom: 40px!important;
    }
  }
  /* **** */
  /* 64px */
  &-64{
    margin-bottom: 64px;

    @include md(){
      margin-bottom: 40px !important;
    }
  }

  &-sm-64{
    @include sm(){
      margin-bottom: 64px!important;
    }
  }

  &-md-64{
    @include md(){
      margin-bottom: 64px!important;
    }
  }
  /* **** */
}
/* MARGIN BOTTOM END */

/* MARGIN TOP BOTTOM */
.mtb{
  /* 16px */
  &-16{
    margin-top: 16px!important;
    margin-bottom: 16px!important;
  }

  &-sm-16{
    @include sm(){
      margin-top: 16px!important;
      margin-bottom: 16px!important;
    }
  }

  &-md-16{
    @include md(){
      margin-top: 16px!important;
      margin-bottom: 16px!important;
    }
  }
  /* **** */
  /* 20px */
  &-20{
    margin-top: 20px!important;
    margin-bottom: 20px!important;
  }

  &-sm-20{
    @include sm(){
      margin-top: 20px!important;
      margin-bottom: 20px!important;
    }
  }

  &-md-20{
    @include md(){
      margin-top: 20px!important;
      margin-bottom: 20px!important;
    }
  }
  /* **** */
  /* 24px */
  &-24{
    margin-top: 24px!important;
    margin-bottom: 24px!important;
  }

  &-sm-24{
    @include sm(){
      margin-top: 24px!important;
      margin-bottom: 24px!important;
    }
  }

  &-md-24{
    @include md(){
      margin-top: 24px!important;
      margin-bottom: 24px!important;
    }
  }
  /* **** */
  /* 40px */
  &-40{
    margin-top: 40px!important;
    margin-bottom: 40px!important;
  }

  &-sm-40{
    @include sm(){
      margin-top: 40px!important;
      margin-bottom: 40px!important;
    }
  }

  &-md-40{
    @include md(){
      margin-top: 40px!important;
      margin-bottom: 40px!important;
    }
  }
  /* **** */
  /* 64px */
  &-64{
    margin-top: 64px;
    margin-bottom: 64px;

    @include md(){
      margin-top: 40px;
      margin-bottom: 40px;
    }
  }

  &-sm-64{
    @include sm(){
      margin-top: 64px!important;
      margin-bottom: 64px!important;
    }
  }

  &-md-64{
    @include md(){
      margin-top: 64px!important;
      margin-bottom: 64px!important;
    }
  }
  /* **** */

}

/* MARGIN TOP BOTTOM END */


.mtb-20{
  margin-top: 20px!important;
  margin-bottom: 20px!important;
}




.mtb-24{
  margin-top: 24px!important;
  margin-bottom: 24px!important;
}


.mtb-40{
  margin-top: 40px!important;
  margin-bottom: 40px!important;

  @include sm(){
    margin-top: 24px!important;
    margin-bottom: 24px!important;
  }

}


.mtb-64{
  margin-top: 64px!important;
  margin-bottom: 64px!important;

  @include sm(){
    margin-top: 40px!important;
    margin-bottom: 40px!important;
  }
}


/* MARGINS END */

.no-resize{
  resize: none;
}

#products-list, .newest-prods{
  &.row{
    @include sm(){
      --bs-gutter-x: 0.75rem !important;
      --bs-gutter-y: 0.0rem !important;
    }
  }
}

.bi-at{
  margin: 0 !important;
  padding: 0 !important;
}



