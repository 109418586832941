@mixin font-size($size){
  font-size: rem($size);
}

@mixin line-height($font-size, $line-height){
  line-height: ($line-height/$font-size);
}

@mixin absolute-centering-both(){
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin absolute-centering-vertically(){
  top: 50%;
  transform: translateY(-50%);
}

@mixin absolute-centering-horizontally(){
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}

@mixin transition($what){
  transition: .2s linear $what;
}

@mixin object-fit($how){
  object-fit: $how;
  font-family: 'object-fit: #{$how};';
  -o-object-fit: $how;
}