.hp-partners {
    min-height: 40px;
    padding: 35px 0;

    .partners-images-grid{

        .partners-images-grid-item{

            img{
                max-width: 200px;
                height: auto;
                padding: 30px;
            }
        }
        
    }
}
