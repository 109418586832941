//base
$base-font-size: 16px;
$base-spacing: 20px;

//colors
$color-black: #000;
$color-blue: #253786; //changed from #27348B 11.11.2022
$color-darker-blue:#182767;
$color-darkest-blue: #0E1639; //zmenit nazev promenne vsude kde pouzito TODO negro
$color-dark-blue: #111827;
$color-grey: #374151;
$color-middle-grey: #6B7280;
$color-light-grey: #D1D5DB;
$color-primary: #7A82B8;
$color-primary-disabled-bg: #D6DCF2;
$color-primary-disabled-text: #6E81D3;
$color-outline-hover-bg: #F9FAFB;

$color-error: #EF4444;
$color-warning: #ef8044;
// menu
//$menu-height: 148px;
//$menu-height-responsive: 50px;