.filter-wrapper{
  width: 100%;

}

.products-filter__group {

  /*@media ($large){
    display: flex;
  }*/

  @media screen and (max-width: 767px){
    margin-bottom: 20px;
  }

  span {
    color: $color-dark-blue;
    @include font-roboto;
    font-weight: 400;
    @include font-size(16px);
    line-height: 20px;


    /*@media ($large){
      padding-top: 18px;
      margin-right: 15px;
    }*/
  }

  /*&.js-productsTags {
    span {
      padding-top: 0;
    }
  }*/
}

.products-filter__row{
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  padding-top: 8px;
  justify-content: space-between;
}
.products-select {
  position: relative;
  width: 100%;
  max-width: 295px;

  @media screen and (max-width: 767px){
    max-width: 100%;
  }
}

.selectProducts {
  position: relative;
  padding-top: 4px;
  &.is-active {
    .selectProducts-options {
      display: block;
    }
  }
}

.selectProducts-trigger {
  position: relative;
  @include font-roboto;
  @include font-size(16px);
  background-color: #fff;
  border: 1px solid $color-light-grey;
  border-radius: 4px;
  cursor: pointer;
  padding: rem(12px);
  color: #9CA3AF;

  &:after {
    position: absolute;
    content: "";
    top: 18px;
    right: 12px;
    width: 16px;
    height: 16px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%236B7280' class='bi bi-chevron-down' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
  }

  &:hover {
    border-color: rgba($color-grey, .5);
  }
}

.selectProducts-options {
  position: absolute;
  top: calc(100% - 4px);
  left: 0;
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #D1D5DB;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  z-index: 1;
  display: none;
  border-top: unset;
}

.selectProducts-option {
  position: relative;
  padding: 8px 12px;
  @include font-roboto();
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  &:first-child{
    &:after {
      position: absolute;
      content: unset;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23ffffff' class='bi bi-chevron-down' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
      display: block;
      top: 17px;
      right: 18px;
      width: 16px;
      height: 16px;
      z-index: 100;
    }
  }

  &:hover {
    background-color: #F9FAFB;
    cursor: pointer;
  }

  &.active {
    background-color: #D6DCF2;
    color: #253786;
    cursor: pointer;
  }

}

/*
.pi--filter-item{
  display: flex;
  flex-direction: column;
  margin-right: 16px;

  label{
    padding-bottom: 4px;
    font-size: 14px;
  }



  &--right{
    display: flex;
    flex-direction: row;
    vertical-align: middle;
    justify-content: space-between;
    width: 100%;
    .form-check{
      padding-top: 32px;
    }

    .btn-group{

      padding-top: 24px;
    }
  }
}*/


.pi--submenu-wrapper{
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  .pi--submenu-item{
    border-radius: 4px;
    border: 1px solid $color-light-grey;
    width: 176px;
    height: 74px;
    display: flex;
    justify-content: space-between;
    padding: rem(24px) rem(12px);
    @include font-size(14px);
    @include font-roboto;
    font-weight: 400;
    line-height: 16px;
    cursor: pointer;
    text-decoration: none;
    color: $color-grey;
    align-items: center;

    @include md(){
      width: calc(100vw / 2 - 20px);
      max-width: unset;
    }

    @include sm(){
      width: calc(100vw / 2 - 20px);
      max-width: unset;
    }


    @supports (-webkit-touch-callout: none) {
      width: calc(100vw / 2 - 20px);
      max-width: unset;
    }


    &:hover{
      border: 1px solid #D1D5DB;
      background: #F9FAFB;
      color: $color-grey;
    }

    &.active, &:active{
      border: 1px solid #253786;
      background: #fff !important;
      color: #253786;
    }




    &:hover{
      background: #F9FAFB;
    }

    &.disabled{
      background: #F9FAFB;
      cursor: unset;
      pointer-events: none;
      a{
        color: #D1D5DB;

        &:hover{
          cursor: unset;
        }
      }

    }

    &.active{
      background: #fff;
      border: 1px solid #253786;

      a{
        color: #253786;
      }

      i{
        color: rgba(37, 55, 134,1) !important;
      }
    }
  }
}
