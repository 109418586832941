:root {
  --display-dropdown: #1e90ff;
  --white: #ffffff;
}


.drp-dwn{
  position: relative;

  .dropdown-menu{
    box-sizing: border-box;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 8px 0px;
    position: absolute;
    background: #FFFFFF;
    border: 1px solid #D1D5DB;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    display: none;

    &.fullwidth{
      width: 100%;
    }

    &.show{
      display: flex;
    }

  }

  .dropdown-item{
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: $color-grey !important;
    flex: none;
    order: 0;
    flex-grow: 1;
    padding: 8px 12px;

    &.menu{
      height: 32px;
      padding-top: 8px;
    }

    &.active, &:active{
      background: #D6DCF2!important;
      color: #253786 !important;
    }

  }
}

.drp-user {

  .dropdown-navigation-link {
    span {
      transform: translateY(6px);
    }
  }

  &:hover {
    .dropdown-menu {
      display: block;
    }
  }
}
