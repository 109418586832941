.hp-newsletter {
    padding: rem(40px) 0;
    height: auto;
    min-height: 240px;
    margin-bottom: rem(40px);

}

.hp-newsletter-wrapper {
    position: relative;
    border-radius: 8px;
    background: url('../images/nwl-bg.svg') no-repeat;
    background-size: cover;
    @include sm(){
        background-position: -704px;
    }
    background-position: left;
    img{
        width: 100%;
        height: auto;
    }

    .hp-newsletter-content {
        align-items: center;
        width: 100%;
        height: 200px;
        z-index: 2;
        padding: 40px;


        @include md(){
            height: auto;
            padding: 24px;
        }

        .flexbox {
            height: 100%;
        }

        p {
            @include font-inter;
            font-weight: 400;
            @include font-size(16px);
            line-height: 24px;
            color: #fff;
            margin-top: rem(8px);
            margin-bottom: 0;
        }
    }
}