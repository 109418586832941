.product-gallery{
  display: flex;
  flex-direction: column;
}

.product-gallery-big-wrapper {
  min-height: 320px;
  max-height: 400px;
  overflow: hidden;

  .product-gallery-showcase-image{
    text-align: center;
    position: relative;
    padding: rem(40px) 0 0;

    img {
      width: auto;
      height: auto;
      max-width: 100%;
      margin: 0 auto;
    }
  }

}

.product-gallery-list-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 60px;
  .product-gallery-list-image{
    width: 100% !important;
    max-width: 90px;
    height: 80px;
    cursor: pointer;


    &.tns-nav-active {
      border: 1px solid $color-blue;
      border-radius: 8px;
    }

    img{
      width: auto;
      height: 60px;
      margin: 10px auto;
      display: block;
    }
  }
}

.product-info {

  h4{
    @include sm(){
      margin-top: 24px;
    }
  }

  .product-info-sn {
    font-size: 14px;
    line-height: 20px;
    margin: 20px 0 0 0;
    padding: 0;
    color: #374151;
  }

  .product-info-man {
    font-size: 14px;
    line-height: 20px;
    margin: 0;
    padding: 0;
    color: #374151;
  }

  .product-info-stock-info-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: rem(20px);
  }

  .product-info-description{
    margin: 20px 0 0 0;
    color: #374151;
    font-size: 16px;
    line-height: 24px;
  }

  .product-info-price{
    color: #111827;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    margin: 20px 0 0 0;
  }

  .product-info-price-taxes{
    color: #374151;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-top: 4px;
  }

  .product-info__badges {
    margin-bottom: rem(12px);
    margin-right: rem(20px);
  }

  .product-info-ask-link{
    vertical-align: middle;
    display: flex;


    a{
      text-decoration: none;
      color: #D1D5DB;
      vertical-align: middle;
      display: flex;

      i{
        padding-right: rem(8px);
      }
    }
  }
}

.product-info-buttons {
  margin-top: rem(64px);

  .number-input {
    margin-right: rem(20px);
  }
}

.product-tabs{

  .nav-tabs{
    border: 0;
    outline: 0;

    .nav-item-custom{
      border: 0;
      border-bottom: 1px solid $color-light-grey;
      outline: 0;


      &:hover{
        border: 0;
        outline: 0;
      }

      .nav-link {
        color: $color-grey;
        @include font-roboto;
        @include font-size(16px);
        line-height: rem(20px);
        cursor: pointer;
        border: 0;
        border-bottom: 1px solid transparent;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }

      .nav-link.active{
        color: $color-blue;
        border-bottom: 1px solid $color-blue;
        outline: 0;
      }

      .nav-link:hover, .nav-link:focus{
        border-bottom: 1px solid $color-blue;
        outline: 0;
      }
    }
  }
}



.tab-pane{

  .description-title{
    font-size: rem(20px);
    line-height: 28px;
    font-weight: 700;
  }

  .description-text{
    font-size: rem(16px);
    line-height: 24px;
    font-weight: 400;
  }

}