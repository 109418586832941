.cart-steps-nav {
  margin: rem(20px) 0 rem(76px);

  @include sm {
    margin: rem(24px) 0 rem(24px);
  }

  .nav-tabs {
    border: 0;
    outline: 0;

    .nav-item-custom{
      border: 0;
      border-bottom: 1px solid $color-light-grey;
      outline: 0;
      width: 33.33%;
      text-align: center;
      color: $color-grey;
      @include font-roboto;
      @include font-size(16px);
      line-height: rem(20px);
      cursor: pointer;
      border-bottom: 1px solid transparent;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      padding: rem(12px);

      @include sm {
        display: none;
        width: 100%;
      }

      &.active{
        color: $color-blue;
        border-bottom: 1px solid $color-blue;
        outline: 0;

        @include sm {
          display: block !important;
        }
      }

      &:hover {
        border: 0;
        outline: 0;
      }

      .nav-link {
        color: $color-grey;
        @include font-roboto;
        @include font-size(16px);
        line-height: rem(20px);
        cursor: pointer;
        border: 0;
        border-bottom: 1px solid transparent;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        padding: rem(12px);

        //@include sm {
        //  padding: rem(16px) rem(12px);
        //}
      }

      .nav-link.active{
        color: $color-blue;
        border-bottom: 1px solid $color-blue;
        outline: 0;
      }

      .nav-link:hover, .nav-link:focus{
        border-bottom: 1px solid $color-blue;
        outline: 0;
      }
    }
  }
}