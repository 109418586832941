$screen-fhd: 1920px;
$screen-xl: 1600px;
$screen-lg: 1440px;
$screen-lmd: 1240px;
$screen-md: 992px;
$screen-sm: 768px;
$screen-xs: 576px;
$screen-xxs: 480px;
$screen-fold: 280px;

@mixin wide-display {
  @media screen and (min-width: $screen-fhd + 1) {
    @content;
  }
}

@mixin xl {
  @media screen and (max-width: $screen-fhd) {
    @content;
  }
}

@mixin lg {
  @media screen and (max-width: $screen-lg - 1px) {
    @content;
  }
}

@mixin lmd {
  @media screen and (max-width: $screen-lmd - 1px) {
    @content;
  }
}

@mixin md {
  @media screen and (max-width: $screen-md - 1px) {
    @content;
  }
}

@mixin sm {
  @media screen and (max-width: $screen-sm - 1px) {
    @content;
  }
}

@mixin xs {
  @media screen and (max-width: $screen-xs - 1px) {
    @content;
  }
}

@mixin xxs {
  @media screen and (max-width: $screen-xxs - 1px) {
    @content;
  }
}

@mixin breakpoint($breakpoint) {
  @media screen and (max-width: $breakpoint) {
    @content;
  }
}

@mixin breakpointMin($breakpoint) {
  @media screen and (min-width: $breakpoint) {
    @content;
  }
}