.forgot-password{
  width: 568px;
  margin: 0 auto 544px auto !important;
  @include font-roboto();
  font-style: normal;


  @include breakpoint($screen-sm){
    width: 100%;
    margin: 0 auto 40px auto !important;
  }

  &--form-wrapper{

    &--button-wrapper{
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: end;
      margin-top: 24px;





    }

  }

}
