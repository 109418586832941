.cart-steps-products {

  .product-table {
    //width: 100%;
    border-collapse: separate;
    border-spacing: 0 20px;
    width: calc(100% - 4px);
    margin: 0 auto;

    thead {
      td {
        @include font-roboto;
        font-weight: 700;
        @include font-size(16px);
        line-height: 24px;
        padding: rem(20px) rem(20px) 0;
        color: $color-dark-blue;

        &:first-child {
          width: 100px;
        }
        &:nth-child(2){
          width: 50%;
          max-width: 500px;

          @include md {
            width: 40%;
          }
        }
        &:nth-child(4){
          text-align: right;
        }
        &:last-child {
          width: 90px;
          text-align: center;
        }
      }
    }

    .product-table-product {
      text-align: left;
      padding-left: 0;
    }
    .product-table-counter {
      text-align: center;
    }
    .product-table-price {
      text-align: center;
    }

    tbody {

      tr {
        border-radius: 4px;
        box-shadow: 0 4px 10px rgba(#000, 0.12);
      }

      td {
        padding: rem(12px);

        &:nth-child(4){
          padding: 0 rem(20px) 0;
          text-align: right;
        }
        &:last-child {
          text-align: center;
        }
      }
    }
  }

  .cart-steps-products__image {
    //max-width: 56px;
    text-align: center;

    img {
      width: auto;
      max-width: 78px;
      height: 78px;
    }
  }

  .cart-steps-products__title {
    @include font-roboto;
    font-weight: 700;
    @include font-size(16px);
    line-height: 24px;
  }

  .cart-steps-products__counter {
    text-align: center;
  }

  .cart-steps-products__price {
    text-align: center;

    strong {
      display: block;
      @include font-roboto;
      font-weight: 700;
      @include font-size(16px);
      line-height: 24px;
      color: $color-dark-blue;
    }
    small {
      display: block;
      @include font-roboto;
      font-weight: 400;
      @include font-size(12px);
      line-height: 20px;
      color: $color-grey;
    }
  }

  .cart-steps-products__actions {
    a {
      color: $color-grey;
    }
  }

  //
  // MOBILE view
  //

  .cart-item--mobile {
    display: flex;
    flex-direction: row;
    column-gap: rem(16px);
    padding: rem(12px);
    //box-shadow: 0 4px 10px rgba(#000, 0.12);
    border-radius: 4px;
    width: calc(100% - 8px);
    margin: 0 auto;

    & + .cart-item--mobile {
      margin-top: rem(16px);
    }

    &:last-child {
      margin-bottom: rem(12px);
    }
  }

  .cart-item--mobile__content {
    display: block;
    flex: 0 1 80%;
  }

  .cart-steps-products__image--mobile {
    display: flex;
    align-items: center;
    flex: 0 1 20%;
    text-align: center;

    img {
      width: auto;
      max-width: 78px;
      height: 78px;
      margin: 0 auto;
    }
  }

  .cart-steps-products__title--mobile {
    display: block;
    @include font-roboto;
    font-weight: 700;
    @include font-size(16px);
    line-height: 24px;
    margin-bottom: rem(8px);
  }

  .cart-steps-products__counter--mobile {
    display: flex;
    text-align: left;
    justify-content: space-between;


    a {
      color: $color-grey;
      line-height: 46px;
      padding: 0 rem(12px);

      i {
        vertical-align: bottom;
      }
    }
  }

  .cart-steps-products__price--mobile {
    display: block;
    text-align: left;
    margin-bottom: rem(8px);

    strong {
      display: block;
      @include font-roboto;
      font-weight: 700;
      @include font-size(16px);
      line-height: 24px;
      color: $color-dark-blue;
    }
    small {
      display: block;
      @include font-roboto;
      font-weight: 400;
      @include font-size(12px);
      line-height: 20px;
      color: $color-grey;
    }
  }

  .cart-steps-products__actions--mobile {
    display: block;
    a {
      color: $color-grey;
    }
  }
}

/*
.cart-steps-content {
  @include sm {
    margin-top: rem(40px);
  }
}*/

.cart-steps-subtotal {
  @include sm {
    width: calc(100% - 8px);
    margin: rem(-36px) auto 0;
  }
}

.table-wrapper {
  display: block;
  overflow-x: auto;
  width: 100%;
}


.table-cart {
  display: table;
  border-collapse: collapse;
  table-layout: fixed;

  //border: 1px solid #ccc;
  margin: 0;
  padding: 0;
  width: 100%;

  /*tbody {
    tr {
      background: #E6007E1A;

      &:nth-child(odd){
        background: #E6007E00;
      }
    }
    td {
      padding: rem(15px) rem(8px);
      line-height: 1.3;

      &:first-child {
        padding-left: rem(30px);
        @include font-bold;
      }
    }
  }

  thead {
    background: $color-blue;
  }

  th {
    text-align: left;
    color: white;
    padding: rem(15px) rem(8px);
    @include font-size(22px);

    &:first-child {
      padding-left: rem(30px);
    }
  }*/

  @include sm {
    border: 0;

    thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }

    th, tbody td {
      &:first-child {
        padding-left: rem(8px);
      }
    }

    tr {
      border-bottom: 3px solid #ddd;
      display: block;
      margin-bottom: rem(20px);
    }

    td {
      //border-bottom: 1px solid #ddd;
      display: block;
      font-size: rem(14px);
      text-align: center;

      &:nth-child(1){
        float: left;
      }

      /*&:nth-child(1), &:nth-child(2){
        text-align: center;
      }
      &:nth-child(1) {
        display: inline-block;
        // display: table-cell;
      }*/
    }

    td {
      &:before {
        content: attr(data-label);
        float: left;
        text-transform: uppercase;
      }

      &:last-child {
        border-bottom: 0;
      }
    }
  }

}