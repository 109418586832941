.sidebar {
  width: 100%;

  @include md {
    display: none;
  }
}

.sidebar-categories {
  list-style: none;
  margin: 0;
  padding-left: 0;
  width: 100%;
}

.sidebar-item {
  width: 100%;
  margin: 0;
  ul{
    list-style: none;
    margin-top: 0;
    margin-bottom: 0;
    li{
      margin-left: -2.3em;
      line-height: 16px;
      padding: rem(16px) rem(12px);
      @include font-size(14px);
      //color: #374151;

      &:hover{
        background: #F9FAFB !important;
        border-radius: 4px;
        color: $color-grey !important;
      }

      &.active{
        a {
          color: #253786 !important;
        }
      }

      a {
        padding-left: 4px;
        line-height: 20px;
      }





    }
  }

}

.sidebar-link {

  display: flex;
  width: 100%;
  color: $color-grey;
  text-decoration: none;
  position: relative;
  transition: .25s ease;
  line-height: 16px;
  @include font-size(14px);
  padding: rem(16px) rem(12px);
  justify-content: space-between;
  align-items: center;

  @include breakpoint($screen-fold){
    word-break: break-word;
  }

  i {
    @include font-size(20px);
    color: $color-middle-grey;
    transform: rotate( 0deg );
    transition: transform 150ms ease;
  }

  &:hover{
    background: #F9FAFB;
    border-radius: 4px;
    color: $color-grey;
  }

  &[aria-expanded="true"], &.active{
    background: #D6DCF2;
    border-radius: 4px;
    color: $color-blue;

    i{
      transform: rotate( -180deg );
      transition: transform 150ms ease;
    }
  }
}

.sidebar-producers {
  padding: rem(20px) 0;
}
.producers-selects {
  padding: rem(8px);

  .form-check{
    padding: 0;
    input{
      float: right;
    }

    .form-check-input:checked{
      background-color: $color-dark-blue!important;
    }
  }
}

@keyframes chevron-rotation-180 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
}

.respo-sidebar{
  flex-direction: row;
  flex-wrap: wrap;
  gap: 12px;
  display: none;

  @include md(){
    display: flex;
    justify-content: start;
  }

  @include sm(){
    justify-content: start;
  }
  @include xs(){
    justify-content: start;
  }

  @include xxs(){

    justify-content: space-between;
  }

  .sidebar-item{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    width: 100%;
    max-width: 150px;
    height: 89px;
    margin: 0;
    background: #FFFFFF;
    justify-content: space-between;
    border: 1px solid #D1D5DB;
    border-radius: 4px;
    flex: none;
    order: 0;
    flex-grow: 0;
    flex-wrap: wrap;

    .sidebar-link{
      @include font-size(14px);
      padding: rem(12px);
      max-width: unset;
    }

    @include md(){
      width: calc(100vw / 2 - 20px);
      max-width: unset;
    }

    @include sm(){
      width: calc(100vw / 2 - 20px);
      max-width: unset;
    }


    @supports (-webkit-touch-callout: none) {
      width: calc(100vw / 2 - 20px);
      max-width: unset;
    }
  }
}