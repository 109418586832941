.page--cart {
  .breadcrumbs {
    @include md {
      margin: rem(16px) auto 0;
    }
  }
}

.breadcrumbs {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  list-style: none;

  @include md {
    margin: rem(16px) auto rem(24px);
  }

  li {
    color: $color-middle-grey;
    @include font-size(12px);
    @include font-roboto;
    font-weight: 400;
    line-height: rem(16px);

    a {
      color: $color-middle-grey;
      text-decoration: none;
    }

    //&.active {
    //  color: $color-blue;
    //}
    &:last-child {
      color: $color-blue;
    }

    &:before {
      float: left;
      padding-right: rem(8px);
      color: $color-light-grey;
      content: "/";
    }
    &:first-child {
      &:before {
        display: none;
      }
    }

    & + li {
      padding-left: rem(8px);
    }
  }
}

.breadcrumbs-back {
  @include font-roboto;
  font-weight: 400;
  @include font-size(14px);
  line-height: rem(16px);

  a {
    text-decoration: none;
    color: $color-grey;
    display: flex;
    justify-content: start;
    align-content: center;
    svg {
      fill: $color-grey;
      margin-right: rem(12px);
    }

    &:hover{
      color: $color-dark-blue;

      svg {
        fill: $color-dark-blue;
      }
    }
  }
}


