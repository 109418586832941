/*#slider {
  position: relative;
  width:100%;
  height: 500px;

  @include sm(){
    width: 104%;
    height: 1023px;
  }

  @include breakpoint($screen-fold){
    height: 1223px;
  }
}

.slide {
  position: absolute;
  width:100%;
  background: white;

  @include md{
    flex-wrap: wrap;
  }
}*/

.slider {
  margin: 0 auto;
}

.slick-slide {
  margin:0 10px;

  @include sm(){
    margin:0 6px;
  }
}
.slick-list {
}

.slick-disabled {
  display: none !important;
}