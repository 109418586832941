.form-control {
  padding: rem(12px) rem(12px) !important;
  @include font-inter;
  @include font-size(16px);
  line-height: 24px;
  border-radius: 8px;
  border: 1px solid $color-light-grey;

  &::placeholder {
    color: $color-light-grey !important;
    opacity: 1;
  }
}