footer{
    background: url("../../assets/images/krouzky_dark.svg") right bottom, #253786;
    background-size: 383px 278px;
    background-repeat: no-repeat;
    width: 100%;
    padding: 64px 0 16px 0;
    color: #fff;
    font-weight: 400;
    @include font-size(14px);
    line-height: 20px;
    @include font-roboto();
    position: relative;

    .krouzkyBG{
        position: absolute;
        bottom: 0;
        right: 0;
    }

    h6, p{
        color: #fff;
        margin-bottom: 8px;
    }

    a{
        text-decoration: none;
        color: #fff;
    }

    .footer-logo{


        .ems-logo-svg{
            margin-right: 20px;

            @include sm(){
                margin-top: 16px;
            }

            @include breakpoint($screen-fold){
                width: 100%;
            }

            &:nth-child(2){
                @include sm(){
                    margin-top: 16px;
                }
            }


        }
    }

    .footer-socials{
        width: 187px;
        margin: 0 auto;

        @include sm(){
            margin: 0;
        }

        a{
            text-decoration: none;
            color: #fff !important;
            font-size: 20px;
        }
    }


    .footer-text-icon-wrapper{

        &.mt-16{
            margin-top: 16px;
        }

        i{
            margin-right: 8px;
        }
    }

    .footer-copyrights{
        padding-top: 43px;
        font-weight: 400;
        @include font-size(12px);
        line-height: 20px;
        color: $color-primary;
    }
}