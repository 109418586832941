
.pnb{

  h3{
    @include font-roboto();
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.02em;
    color: $color-dark-blue;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
  }

  &--undertitle{
    @include font-roboto();
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: $color-grey;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
  }

  &--content-text{
    @include font-roboto();
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $color-grey;
    flex: none;
    order: 2;
    align-self: stretch;
    flex-grow: 0;


  }

  &--text-banner{
    width: 100%;
    max-width: 666px;
    height: auto;
  }

  img{
    max-width: 666px;

    @include md(){
      max-width: 100%;
    }
  }

  &--sortiment{
    display: flex;
    flex-direction: row;
    justify-content: start;
    flex-wrap: wrap;
    width: 100%;

    @include sm(){
      padding:16px 12px;
    }

    &--item{
      display: flex;
      flex-direction: column;
      justify-content: start;
      margin-top: 24px !important;

      &--image-wrapper{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 140px;
        img{
          vertical-align: middle;
          width: auto;
          height: auto;

        }
      }

      &--title{
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: -0.02em;
        color: $color-dark-blue;
        flex: none;
        order: 1;
        flex-grow: 0;
      }

      &--description{
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $color-grey;
        flex: none;
        order: 2;
        align-self: stretch;
        flex-grow: 0;

      }

    }
  }

}

.pnb--2-cols{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  &--big{
    width: 100%;
    max-width: 598px;
    display: flex;
    flex-direction: column;
    padding-left: 44px;

    @include sm(){
      padding:16px 12px;

    }

    .btn{
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 274px;
      font-size: 16px;
      line-height: 20px;
    }



  }

  &--small{
    display: flex;
    align-items: center;
    img{
      @include sm(){
        width: 50%;
        padding-left: 12px;
      }

    }

  }

  &--row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex: none;
    order: 2;
    align-self: stretch;
    flex-grow: 0;
    flex-wrap: wrap;

    &--texts{
      display: flex;
      flex-direction: column;

    }

    .btn{
      vertical-align: middle;

    }
  }

}
