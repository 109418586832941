.cart-dropdown {
  margin: 20px 0;
  position: absolute;
  z-index: 1001;
  background: white;
  //width: 100%;
  width: 85vw;
  max-width: 415px;
  border-radius: 4px;
  padding: rem(12px) rem(16px);
  display: none;
  top: 17px;
  right: 0;
  box-shadow: 0 4px 10px rgba(#000, .12);
  &.empty{
    max-width: 159px;
  }
  &.cart-dropdown--opened {
    display: block;
  }

  &:after {
      bottom: 100%;
      right: 10px;
      border: solid transparent;
      border-bottom-color: #fff;
      border-width: 8px;
      content: "";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      margin-left: -8px;
  }

  .cart-dropdown-header {
    border-bottom: 1px solid $color-light-grey;
    padding-bottom: rem(12px);
  }

  .cart-dropdown-items {
    padding: rem(20px) 0 0;
    width: 100%;
    margin-bottom: 1rem;
    vertical-align: middle;
    color: $color-dark-blue;
    @include font-roboto;
    font-weight: 700;
    @include font-size(12px);
    line-height: 20px;

    .cart-dropdown-name {
    }
    .cart-dropdown-amount {
      text-align: center;
      color: $color-grey;
      font-weight: 400;
    }
    .cart-dropdown-price {
      text-align: right;
    }

    td {
      padding: 0 0 rem(12px);
    }
  }

  .cart-dropdown-total {
    border-top: 1px solid $color-light-grey;

    td {
      padding: rem(12px) 0 0;
    }
  }

  .cart-dropdown-checkout {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}