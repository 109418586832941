.request{
  width: 586px;

  @include sm(){
    width: 100%;
  }
}
.request-goods-address{
  display: flex;
  flex-direction: row;
  align-items: baseline;

  i{
    font-size: rem(20px);
    margin-right: 14px;
  }

  p{
    @include font-roboto();
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $color-grey;
    flex: none;
    order: 1;
    flex-grow: 1;
  }
}

.request-goods-contacts{
  display: flex;
  flex-direction: column;

  &--item{
    display: flex;
    flex-direction: row;
    align-items: baseline;

    i{
      font-size: rem(20px);
      margin-right: 14px;
    }

    p{
      @include font-roboto();
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: $color-grey;
      flex: none;
      order: 1;
      flex-grow: 1;
      padding-top: 8px;
    }

  }
}


.request-inputs{

  label{
    @include font-roboto();
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #111827;
    flex: none;
    order: 0;
    flex-grow: 0;

  }

  input[type="text"],input[type="email"]{
    margin-top: 4px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px;
    gap: 8px;
    height: 44px;
    background: #FFFFFF;
    border: 1px solid #D1D5DB;
    border-radius: 4px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
  }

  textarea{
    height: 128px;
  }
}

.form-group-reqf-submit{
  display: flex;
  justify-content: end;
}