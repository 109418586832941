.category-banner{
    background-image: url("../images/krouzky.svg"), linear-gradient(90deg, #F9FAFB 0%, #D6DCF2 100%);
    background-repeat: no-repeat;
    background-position: 100%;
    border-radius: 8px;
    width: 100%;
    height: fit-content;

    padding: 0;

    h2{
        color: #27348B;
    }

    @include sm(){
        background-image: url("../images/krouzkymini.svg"), linear-gradient(90deg, #F9FAFB 0%, #D6DCF2 100%);
    }

}

.banner-content-detail{
    display: flex;

    .banner-content-left{
        padding: 48px 40px;

        /*@include sm(){
            padding: 24px 12px;
        }*/
    }


}



.products-container{
    position: relative;

    .newest-prods-prev-arrow{
        position: absolute;
        width: 44px;
        height: 44px;
        left: 0;
        top: 50%;
        transform: translateY(8%);
        background: #FFFFFF;
        box-shadow: 0px 8px 35px rgba(0, 0, 0, 0.16);
        border-radius: 4px;
        cursor: pointer;

        @include md{
            display: none;
        }

        a{
            width: fit-content;
            height: fit-content;
            color: #253786;
        }

        &.hidden {
            display: none;
        }
    }
    .newest-prods-next-arrow{
        position: absolute;
        width: 44px;
        height: 44px;
        right: 0;
        top: 50%;
        transform: translateY(8%);
        background: #FFFFFF;
        box-shadow: 0px 8px 35px rgba(0, 0, 0, 0.16);
        border-radius: 4px;
        cursor: pointer;

        @include md{
            display: none;
        }

        a{
            width: fit-content;
            height: fit-content;
            color: #253786;
        }

        &.hidden {
            display: none;
        }
    }
}





