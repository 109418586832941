.register{
  width: 568px;
  margin: 0 auto 196px auto;
  @include font-roboto();
  font-style: normal;

  @include breakpoint($screen-sm){
    width: 100%;
  }

  &--title-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @include breakpoint($screen-xs){
      flex-direction: column;
      align-items: start;
    }


    &--texts{
      h2{
        font-weight: 700;
        font-size: 28px;
        line-height: 36px;
        letter-spacing: -0.02em;
        color: #111827;
        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;
      }

      p{
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #374151;
        flex: none;
        order: 1;
        align-self: stretch;
        flex-grow: 0;
      }
    }

  }

  &--forms-wrapper{

    &--form-part{
      margin-top: 40px;

      h3{
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        display: flex;
        align-items: center;
        letter-spacing: -0.02em;
        color: #111827;
        flex: none;
        order: 0;
        flex-grow: 0;
      }

      label{
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #111827;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin-top: 16px;
      }

      input[type="text"],input[type="email"],input[type="password"],input[type="number"]{
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 12px;
        gap: 8px;
        width: 100%;
        height: 44px;
        background: #FFFFFF;
        border: 1px solid #D1D5DB;
        border-radius: 4px;
        flex: none;
        order: 1;
        align-self: stretch;
        flex-grow: 0;
      }

      input[type=number]::-webkit-inner-spin-button,
      input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

    }


    &--submit-row{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-top: 24px;

      @include breakpoint($screen-xs){
        flex-direction: column;
        align-items: start;
      }


      a{
        text-decoration: none;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: #374151;
        flex: none;
        order: 0;
        flex-grow: 0;
      }

      label{
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: #374151;
        flex: none;
        order: 1;
        flex-grow: 0;
      }

      .btn{
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        padding: 12px 20px;
        color: #FFFFFF;

        @include breakpoint($screen-xs){
          margin-top: 16px
        }
      }
    }
  }

}

