:root{
  --search-bar-box-shadow: rgb(13 110 253 / 0%);
  --search-bar-border-color: unset;
}


.preheader {
  background: $color-blue;
  @include font-roboto();
  width: 100%;
  height: 30px;

  @include md {
    display: none;
  }

  .container{
    display: flex;
    flex-direction: row;

    .preheader-contact{
      width: fit-content;
      height: 30px;
      vertical-align: middle;
      display: flex;
      justify-content: space-between;

      a{
        color: #fff;
        text-decoration: none;
        vertical-align: middle;
        font-size: 14px;
        line-height: 16px;
        display: flex;
        flex-direction: row;
        align-items: center;

        i{

          padding-right: 8px;
          font-size: 16px;
        }

        span
        {
          vertical-align: middle;
        }

        &:first-child
        {
          padding-right: 20px;
        }
        &:last-child
        {
          padding-right: 20px;
          span{
            margin-top: 2px;
          }
        }
      }
    }
  }
}

.header {
  box-shadow: 0 4px 20px rgba(#000, .1);

  &.header--sticky {
    position: fixed;
    top: 0;
    left: 0;
    background: #fff;
    width: 100%;
    z-index: 1000;
  }
}

.navigation {
  padding: rem(20px) 0;

  & > .container {
    height: 100%;
    display: flex;
    align-items: center;

    @include md {
      flex-flow: row wrap;
      justify-content: space-between;
    }
  }

}

.header-logo {
  display: flex;
  max-width: 220px;
  svg{
    max-width: 220px;
  }

  @include md {
    flex: 0 1 calc(40%);
  }

  .navbar-brand {
    display: block;

    @include sm {
      display: none;
    }
  }
  .navbar-brand-sm {
    display: none;

    @include sm {
      display: block;
    }
  }
}

.navigation-right {
  flex: 3;
  margin-left: rem(30px);

  @include md {
    order: 2;
    flex: 0 1 calc(55%);
    margin-left: 0;
  }
}

.navigation-items {
  height: 100%;
  display: flex;
  justify-content: end;
  align-items: center;

  @include md {
    display: none;
  }

  .navigation-link {
    color: $color-grey;
    @include font-roboto;
    @include font-size(14px);
    font-weight: 400;
    text-decoration: none;
    margin-left: rem(20px);

    &:hover{
      color: #182767;
    }

    &:active,.active{
      color: #0E1639;
    }

    &:first-child {
      margin-left: 0;
    }

    .icon{
      margin-left: 8px;
    }
  }

  .dropdown-navigation-link {
    color: $color-grey;
    @include font-roboto;
    @include font-size(14px);
    font-weight: 400;
    text-decoration: none;
    //margin-left: rem(24px);
    cursor: pointer;

    &.flex{
      display: flex;
    }
  }

  &.navigation-items--user {
    margin-bottom: rem(15px);
    position: relative;
    padding-right: rem(8px);

    @include md {
      display: flex;
    }

    .navigation-link {
      color: $color-grey;
      @include font-size(14px);
      font-weight: 400;
      text-decoration: none;
      margin-left: rem(18px);
      height: 22px;
      &:first-child {
        margin-left: 0;

      }

      i{
        font-size: 20px;
        vertical-align: inherit;
      }

      span{
        margin-left: 10px;
        vertical-align: middle;
      }
    }
  }

  /*
  * UX ICONS CUSTOM OPTIONS
  */
  .navigation-user {
    @include md {
      span {
        display: none;
      }
    }
  }
  .navigation-hamburger {
    display: none;

    @include md {
      display: block;
    }
  }
  .navigation-cart {
    position: relative;
    small {
      position: absolute;
      top: -3px;
      right: -7px;
      @include font-roboto;
      @include font-size(8px);
      font-weight: 400;
      line-height: 12px;
      background: $color-blue;
      color: #fff;
      padding: rem(2px);
      width: 16px;
      height: 16px;
      vertical-align: baseline;
      text-align: center;
      border-radius: 50%;
    }
  }
}

.header-search {
  flex: 2;

  .input-group {

    input{
      height: 44px;
    }

    .search-control:focus{
      box-shadow: unset !important;
      border: none !important;
      color: #111827 !important;
      @include font-roboto();
    }

    &.search{
      border-radius: 6px;

      &.focused{
        border: 1px solid #6E81D3!important;

        .btn{
          --bs-btn-box-shadow: unset !important;
          border: none !important;
        }
      }
    }
  }


  @include breakpointMin($screen-lg) {
    margin-left: rem(29px);
  }

  @include md {
    display: block;
    flex: 0 1 100%;
    order: 3;
    margin-left: 0;
    margin-top: rem(16px);
  }

  .form-control {
    border: 1px solid $color-light-grey;
    border-right: 0;
    padding: rem(12px);
    font-weight: 400;
    @include font-size(16px);
    line-height: 24px;
    color: $color-grey;
  }

  .btn {
    border: 1px solid $color-light-grey;
    border-left: 0;
    position: relative;
    padding: rem(20px) rem(24px);

    &:hover {
      border: 1px solid $color-light-grey;
      border-left: 0;
    }
    &:active, &:focus, &:focus-visible, &:focus-within {
      color: $color-grey !important;
      background-color: #fff !important;
      border-color: #6E81D3 !important;
    }
  }

  i {
    position: absolute;
    color: $color-grey;
    right: 16px;
    top: 50%;
    transform: translateY(calc(-50% - 2px));
  }
}

.navigation--opened {

}


.nav-responsive {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1001;
  top: 0;
  right: 0;
  background-color: $color-blue;
  overflow-x: hidden;
  padding-top: 60px;
  transition: .5s;

  &.nav-responsive--opened {
    width: 100%;
    padding-left: 12px;
    //width: 280px;

    .navigation-items{
      display: block;
      flex-direction: column;
      justify-content: start;
      //gap: rem(20px);
      margin-top: rem(40px);
      text-align: left;

      .navigation-link{
        @include font-roboto;
        @include font-size(16px);
        font-weight: 400;
        line-height: rem(20px);
        color: #fff;
        margin-left: 0;
        //width: 100%;
        padding: rem(16px) rem(12px);
        text-align: left;
        display: block;

        &.btn-primary {
          border: 1px solid #fff;
          padding: rem(12px) rem(20px);
          max-width: 94px;
          margin-top: rem(8px);
          margin-left: rem(12px);
        }
      }

      .dropdown-navigation-link {
        @include font-roboto;
        @include font-size(16px);
        font-weight: 400;
        line-height: rem(20px);
        color: #fff;
        margin-left: 0;
      }
    }

    .navigation-respo-close{
      display: block;
      width: 100%;
      height: 80px;
      position: absolute;
      top: 0;
      right: 0;
      text-align: right;
      padding: {
        top: rem(28px);
        right: rem(28px);
      }

      a {
        color: #fff;
        font-size: rem(20px);
      }
    }
  }

  .navigation-respo-contact {
    padding: rem(20px) rem(12px) rem(16px);

    a {
      display: block;
      color: #fff;
      @include font-roboto;
      @include font-size(16px);
      font-weight: 400;
      line-height: rem(20px);
      text-decoration: none;

      i {
        margin-right: rem(8px);
      }

      & + a {
        margin-top: rem(28px);
      }
    }
  }
}