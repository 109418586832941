@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css");


@import "fonts";
@import "variables";
@import "functions";
@import "breakpoints";
@import "mixins";
@import "base";
@import "grid";
@import "buttons";
@import "forms";
@import "typography";

@import "components/components";
@import "pages/pages";


//libs
//@import "libs/font-awesome/fontawesome";
@import "../../node_modules/bootstrap/scss/bootstrap";
@import "../../node_modules/tiny-slider/src/tiny-slider";
@import "../../node_modules/slick-carousel/slick/slick.scss";
@import "../../node_modules/slick-carousel/slick/slick-theme.scss";
//@import "../../node_modules/bootstrap-icons/font/bootstrap-icons.scss";

