.title {

  &.title--h1 {
  }

  &.title--section {
    @include font-roboto;
    font-weight: 700;
    @include font-size(36px);
    line-height: 44px;
    letter-spacing: -0.02em;
    color: #000;

    @include md(){
      margin-top: 24px;
    }
  }

  &.title--sidebar {
    @include font-roboto;
    display: block;
    font-weight: 700;
    @include font-size(16px);
    line-height: 24px;
    color: #000;
    margin-bottom: rem(12px);
  }

  &.title--banner {
    @include font-roboto;
    font-weight: 700;
    @include font-size(36px);
    line-height: 44px;
   // padding: 8px 0;

    @include sm(){
      @include font-size(24px);
    }

    span {
      font-weight: 700;
      @include font-size(20px);
      line-height: 28px;
      display: block;
    }

    .title--banner--cat{

    }
  }

  &.title--product {
    @include font-inter;
    font-weight: 700;
    @include font-size(16px);
    line-height: 24px;
    color: #000;


  }

  &.title--nwl {
    @include font-inter;
    font-weight: 700;
    @include font-size(28px);
    line-height: 36px;
    letter-spacing: -0.02em;
    color: #fff;
  }

  &.title--product-detail {
    @include font-roboto;
    @include font-size(28px);
    font-weight: 700;
    line-height: 36px;
    margin: 0;
    padding: 0;

    @include md(){
      margin-top: 24px;
    }
  }

  &.title--cart-steps {
    @include font-roboto;
    @include font-size(32px);
    font-weight: 700;
    line-height: 40px;
    letter-spacing: -0.02em;
    color: $color-dark-blue;
  }

  &.title--cart-subtitle {
    @include font-roboto;
    @include font-size(28px);
    font-weight: 700;
    line-height: 36px;
    letter-spacing: -0.02em;
    color: $color-dark-blue;
  }

}

h1{
  color: $color-dark-blue;
  font-weight: 700 !important;
  line-height: 48px !important;
  letter-spacing: -0.02em;
  @include font-roboto;
  font-size:40px!important;
  margin-bottom: 0 !important;
  padding: 0;

  .display{
    font-weight: 700 !important;
    line-height: 56px!important;
    @include font-roboto;
    font-size:52px!important;
    letter-spacing: -0.02em;
  }

  @include sm(){
    font-size:36px!important;
    line-height: 44px !important;
  }
}

h2{
  color: $color-dark-blue;
  font-weight: 700 !important;
  line-height: 44px !important;
  font-size:36px!important;
  @include font-roboto;
  letter-spacing: -0.02em;
  margin-bottom: 0!important;
  padding: 0;
  @include sm(){
    font-size:32px!important;
    line-height: 40px !important;
  }
}

h3{
  color: $color-dark-blue;
  font-weight: 700 !important;
  line-height: 40px !important;
  @include font-roboto;
  font-size:32px!important;
  letter-spacing: -0.02em;
  margin-bottom: 0!important;
  padding: 0;
  @include sm(){
    font-size:28px!important;
    line-height: 36px!important;
  }
}

h4{
  color: $color-dark-blue;
  font-weight: 700 !important;
  line-height: 36px!important;
  @include font-roboto;
  font-size:28px!important;
  letter-spacing: -0.02em;
  margin-bottom: 0!important;
  padding: 0;
  @include sm(){
    font-size:24px!important;
    line-height: 32px!important;
  }
}

h5{
  color: $color-dark-blue;
  font-weight: 700 !important;
  line-height: 32px !important;
  @include font-roboto;
  font-size:24px!important;
  letter-spacing: -0.02em;
  margin-bottom: 0!important;
  padding: 0;
  @include sm(){
    font-size:20px!important;
    line-height: 28px !important;
  }
}

h6{
  color: $color-dark-blue;
  font-weight: 700 !important;
  line-height: 28px !important;
  @include font-roboto;
  font-size:20px!important;
  letter-spacing: -0.02em;
  margin-bottom: 0!important;
  padding: 0;
  @include sm(){
    font-size:16px!important;
    line-height: 24px;
  }
}

.body-text{
  @include font-roboto;
  @include font-size(16px);
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  padding: 0;
  margin-bottom: 0;
  margin-top: 0;
  color: $color-grey;

  &.small{
    @include font-roboto;
    @include font-size(14px);
    line-height: 20px;

    &-xs{
      @include font-size(12px);
      line-height: 20px;
    }
  }

  &.large{
    @include font-roboto;
    @include font-size(20px);
    line-height: 28px;
  }

  &.bold{
    font-weight: 700;
  }

}
.text-md-left{
  @include md(){
    text-align: left;
  }
}
.link{
  @include font-roboto;
  @include font-size(16px);
  line-height: 20px;
  text-decoration: none;

  &:hover{
    color: #182767;
  }

  &:active,.active{
    color: #0E1639;
  }


  &.small{
    @include font-roboto;
    @include font-size(14px);
    line-height: 16px;

    &-xs{
      @include font-size(12px);
      line-height: 16px;
    }

    &-xxs{
      @include font-size(8px);
      line-height: 12px;
    }
  }

  &.bold{
    font-weight: 700;
  }
}
/* COLORS */
.color-grey{
  color: $color-grey;
}

.color-white{
  color: #fff;
}
.color-headline{
  color: #111827;
}
.color-error{
  color: $color-error;
}
.color-warning{
  color: $color-warning;
}
