.modal-close-outside{
  font-size: rem(20px);
  color: white;
  position: fixed;
  top: 57px;
  right: 57px;

  :hover{
    color:$color-light-grey;
  }
  @include md(){
    top: 41px;
    right: 29px;

  }

}




.modal-content{
  padding: 40px;
  //position: absolute !important;
  width: 568px;
  height: fit-content;
  top: 200px;
  background: #FFFFFF;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.12);
  border-radius: 8px;

  @include md(){
    width: 100%;
    top: 108px;
    padding: 16px;
  }

  h2{
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    letter-spacing: -0.02em;
    color: $color-dark-blue;
    flex: none;
    text-align: center;
  }

  p{
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $color-grey;
    flex: none;
    align-self: stretch;
    text-align: center;

    &.under-title{
      font-weight: 700;
    }
  }

  .modal-form-wrapper{


    .buttons-row{
      flex-direction: row-reverse;
      align-items: center;
      text-align: center;
      justify-content: space-between;
      align-self: stretch;
      flex-grow: 0;
      flex: none;
    }

    input[type="text"],input[type="email"],input[type="password"],input[type="number"]{
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 12px;
      gap: 8px;
      width: 100%;
      height: 44px;
      background: #FFFFFF;
      border: 1px solid #D1D5DB;
      border-radius: 4px;
      flex: none;
      order: 1;
      align-self: stretch;
      flex-grow: 0;
    }

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }





  .btn{
    padding: 10px 12px;
  }
}