.btn {
  padding: 12px 20px !important;
  font-size: 16px !important;
  line-height: 20px !important;
  --bs-btn-font-family:'Roboto', sans-serif;

  &-sm{
    padding: 8px 12px !important;
  }

  &.btn-primary {
    background-color: $color-blue;
    border-color: $color-blue;
    border-radius: 4px;

    &:hover{
      background-color: $color-darker-blue !important;
      border-color: $color-darker-blue !important;
      color: #fff;
    }

    &:active, &.active {
      background-color: $color-darkest-blue !important;
      border-color: $color-darkest-blue !important;
      color: #fff !important;
    }

    &:disabled, &.disabled {
      background-color: $color-primary-disabled-bg !important;
      border-color: $color-primary-disabled-bg !important;
      color: $color-primary-disabled-text;
    }
  }

  &.btn--nwl {

    border-radius: 8px;
    @include font-roboto;
    @include font-size(16px);
    line-height: 24px;
    color: #fff;
    margin-left: 20px;

    @include sm(){
      margin-left: 0;
      margin-top: 20px;
    }
  }

  &.btn--cart-add {
    background-color: $color-blue;
    border-color: $color-blue;
    border-radius: 4px;
    @include font-roboto;
    @include font-size(16px);
    font-weight: 400;
    line-height: 20px;
    color: #fff;

    &:hover {
      background-color: lighten($color-blue, 5);
      color: #fff;
    }
  }

  &.btn--servis {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    gap: 8px;
    width: 133px;
    height: 44px;
    background: #FFFFFF;
    border: 1px solid #D1D5DB;
    border-radius: 4px;
    flex: none;
    order: 1;
    flex-grow: 0;

    &:hover {
      border: 1px solid #D1D5DB;
      background: $color-outline-hover-bg;
      color: $color-grey;
    }

    &:active, &.active {
      border: 1px solid $color-grey;
      background: #fff;
      color: $color-grey;
    }

    &:disabled, &.disabled {
      background-color: #fff;
      border-color: #D1D5DB;
      color: #D1D5DB;
    }
  }

  &.btn--filter {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 20px;
    gap: 8px;
    width: 98px;
    height: 44px;
    background: #fff;
    color: #253786;
    border: 1px solid #253786;
    border-radius: 4px;
    flex: none;
    order: 1;
    flex-grow: 0;
    font-weight: 400;
    @include font-roboto;
    @include font-size(16px);
    line-height: 20px;

    &:hover {
      border: 1px solid $color-grey;
      color: #fff;
      background: #253786;
    }

    &:active, &.active {
      background-color: $color-darkest-blue !important;
      border-color: $color-darkest-blue !important;
      color: #fff !important;
    }
  }

  &.btn-outline{
    background: #FFFFFF;
    border: 1px solid #D1D5DB;
    border-radius: 4px;
    flex: none;
    order: 2;
    flex-grow: 0;

    &:hover {
      border: 1px solid #D1D5DB;
      background: $color-outline-hover-bg;
      color: $color-grey;
    }

    &:active, &.active {
      border: 1px solid $color-grey;
      background: #fff;
      color: $color-grey;
    }

    &:disabled, &.disabled {
      background-color: #fff;
      border-color: #D1D5DB;
      color: #D1D5DB;
    }
  }
  &.btn--steps-back {
    background: #fff;
    border-radius: 4px;
    border: 1px solid $color-light-grey;
    text-align: center;
    @include font-roboto;
    @include font-size(16px);
    line-height: 20px;
    color: $color-grey;

    &:hover {
      border: 1px solid $color-grey;
      background: $color-outline-hover-bg;
      color: $color-grey;
    }

    &:active, &.active {
      border: 1px solid $color-grey;
      background: #fff;
      color: $color-grey;
    }
  }
  &.btn--steps-next {
    background: $color-blue;
    border-radius: 4px;
    border: 1px solid $color-blue;
    text-align: center;
    @include font-roboto;
    @include font-size(16px);
    line-height: 20px;
    color: #fff;

    &:hover {
      background-color: $color-darker-blue !important;
      border-color: $color-darker-blue !important;
      color: #fff;
    }

    &:active, &.active {
      background-color: $color-darkest-blue !important;
      border-color: $color-darkest-blue !important;
      color: #fff !important;
    }

    &:disabled, &.disabled {
      background-color: $color-primary-disabled-bg !important;
      border-color: $color-primary-disabled-bg !important;
      color: $color-primary-disabled-text;
    }

  }


  &.btn--cart-checkout {
    @include font-roboto;
    font-size:14px !important;
    line-height: 16px;
    color: $color-grey;
    background: #fff;
    border: 1px solid $color-light-grey;
    padding: rem(6px) rem(10px) !important;
    border-radius: 4px;

    &:hover {
      border: 1px solid #D1D5DB;
      background: $color-outline-hover-bg;
      color: $color-grey;
    }

    &:active, &.active {
      border: 1px solid $color-grey;
      background: #fff;
      color: $color-grey;
    }
  }

}

