.cart-steps-delivery {
  margin-top: rem(24px);

  .cart-delivery{


    .form-check{
      display: flex;
      flex-direction: row;
      justify-content: start;
      align-items: center;

      .form-check-input{
        margin-right: 12px;
        margin-top: 0;

        &:checked{
          border-color: $color-blue;
          background-color: $color-blue;
        }
      }

      label{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
      }

    }
  }
  .order-on-company{
    display: none;
    &.active{
      display: block;
    }
  }


  .cart-contact{

    &--form-part{

      &:nth-child(2){
        margin-top: 24px;
      }

      h3{
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        display: flex;
        align-items: center;
        letter-spacing: -0.02em;
        color: #111827;
        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;
      }

      p{
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        align-items: center;
        color: #374151;
        flex: none;
        order: 1;
        align-self: stretch;
      }

      label{
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #111827;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin-top: 16px;
      }

      input[type="text"],input[type="email"],input[type="password"],input[type="number"]{
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 12px;
        gap: 8px;
        width: 100%;
        height: 44px;
        background: #FFFFFF;
        border: 1px solid #D1D5DB;
        border-radius: 4px;
        flex: none;
        order: 1;
        align-self: stretch;
        flex-grow: 0;
      }

      input[type=number]::-webkit-inner-spin-button,
      input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      .form-check{
        display: flex;
        flex-direction: row;
        align-content: center;
        align-items: center;
        input[type="checkbox"]{
          margin-right: 12px;
        }

        label{
          height: inherit;
          margin-top: 3px;

        }
      }
    }
  }
}