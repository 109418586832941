.flexbox {
  display: flex;
  row-gap: rem(20px);

  &.flexbox--row {
    flex-flow: row wrap;
  }

  &.flexbox--justify-center {
    align-items: center;
    justify-content: center;
  }

  &.flexbox--wrap {
    flex-wrap: wrap;
    flex-direction: row;
  }
}