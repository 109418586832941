.badges {
  border-radius: 4px;
  padding: rem(2px) rem(8px);
  width: fit-content;
  @include font-inter;
  @include font-size(12px);
  line-height: 20px;


  &.badge--stock-in {
    background: #BBF7D0;
    color: #14532D;
  }

  &.badge--stock-out {
    background: #FECACA;
    color: #7F1D1D;
  }

  &.badge--action {
    background: #253786;
    color: #FFFFFF;
  }

  &.badge--new {
    background: #FFD280;
    color: #78350F;
  }
}