.products-list {
    a {
        text-decoration: none;
        color: inherit;

        &:hover {
            color: inherit;
        }
        margin-bottom: 24px;
        @include sm(){
            margin-bottom: 12px;
        }
    }
}

.product-item {
    border: 1px solid $color-light-grey;
    border-radius: 8px;
    min-height: 300px;
    padding: rem(16px);
    display: flex;
    flex-direction: column;
    cursor: pointer;


     &:hover{
        border: 1px solid $color-grey;
    }

    .product-item__image {
        width: 100%;
        height: 160px;
        padding: rem(20px) 0;
        text-align: center;
        margin-bottom: rem(16px);

        img {
            height: 100%;
            width: auto;
            max-height: 160px;
            margin: 0 auto;
            display: block;

            @include md(){
                max-width: 100%;
                width: auto;
                height: auto;
                max-height: 120px;
            }

            @include xs(){
                max-width: 100%;
                width: auto;
                height: auto;
                max-height: 100px;
            }

            @include breakpoint($screen-fold){
                max-width: 100%;
                width: 100%;
                height: auto;
                max-height: 100px;
            }
        }
    }

    .product-item__badges {
        margin-bottom: rem(12px);
    }

    .product-item__content {
        margin-bottom: rem(16px);
        .title{
            height: 48px;

            @include sm(){
                height: 90px;
            }

            @include breakpoint($screen-fold){
                height: 150px;

            }
        }

        .product-item-params {
             margin-top: rem(12px);

             p {
                  @include font-size(12px);
                  color: $color-grey;
                  line-height: 20px;
                  margin-bottom: rem(4px);
              }
        }

    }

    .product-item__price {
        .product-item-price {
            //font-family: 'Inter';
            font-weight: 700;
            @include font-size(20px);
            line-height: 28px;
            margin-bottom: rem(6px);
        }
        .product-item-price--tax {
            //font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            @include font-size(12px);
            line-height: 20px;
            color: $color-grey;
        }
    }


}



