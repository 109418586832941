
input:not([type="submit"]):not([type="button"]):not(.search-control):not(.quantity){
  border: 1px solid #D1D5DB;
  border-radius: 4px;
  height: 44px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  @include font-roboto();

  &:focus{
    box-shadow: unset !important;
    border-color: #6E81D3 !important;
    color: #111827;
  }

  &::placeholder{
    @include font-roboto();
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #9CA3AF;

  }
  &.invalid{
    border: 2px #FCA5A5 solid!important;
  }
}

.form-group{
  label{
    padding-bottom: 4px;
  }

  .info-message{
    display: none;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    @include font-roboto();
    margin-top: 8px;

    &.active{
      display: block;
    }
  }
}