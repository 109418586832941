.contact-contacts{
  h3{
    @include font-roboto();
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: #111827;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
  }

  i{
    font-size: rem(20px);
    margin-right: 14px;
  }

  p{
    @include font-roboto();
    font-style: normal;
    font-weight: 400;
    flex: none;
    order: 1;
    flex-grow: 1;
    padding-top: 8px;
  }

}

.contact-contacts-column{
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;

  &--item{
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: baseline;
  }
}

.contact-map{
  margin-bottom: 196px;

  @include md(){
    margin-top: 16px !important;
    margin-bottom: 0;
  }

  iframe{
    overflow: hidden;
    border-radius: 8px;
    height: 408px;
  }

}