.register-result{
  width: 568px;
  margin: 0 auto 196px auto !important;
  @include font-roboto();
  font-style: normal;
  text-align: center;
  padding: 40px !important;


  @include breakpoint($screen-sm){
    width: 100%;
    margin: 0 auto 40px auto !important;
  }

  i{
    font-size: 53px;

    &.succeed{
      color: #22C55E;
    }

    &.error{
      color: #c52222;
    }
  }

  h2{
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    letter-spacing: -0.02em;
    color: #111827;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin-top: 21px;
  }

  p{
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #374151;
    margin-top: 16px;
  }

  .under-title{
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #374151;
  }

  .btn{
    margin-top: 40px;
    padding: 12px 20px;
  }
}
