.terms-and-conditions{
  color: $color-dark-blue;

  h2{
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    letter-spacing: -0.02em;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
  }

  p{
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $color-grey;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
  }

  &--row{
    h3{
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      letter-spacing: -0.02em;
      color: $color-dark-blue;
      flex: none;
      order: 0;
      align-self: stretch;
      flex-grow: 0;
    }


    ul{
      li{
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: $color-grey;
        flex: none;
        order: 1;
        align-self: stretch;
        flex-grow: 0;
        &::marker{
          font-size: 12px;
        }
      }
    }
  }

}