@font-face {
  font-family: 'Font Awesome 6 Free';
  src: url('../fonts/font-awesome/fa-regular-400.woff2') format('woff2'),
  url('../fonts/font-awesome/fa-regular-400.ttf') format('ttf');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Font Awesome 6 Free';
  src: url('../fonts/font-awesome/fa-brands-400.woff2') format('woff2'),
  url('../fonts/font-awesome/fa-brands-400.ttf') format('ttf');
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Font Awesome 6 Free';
  src: url('../fonts/font-awesome/fa-solid-900.woff2') format('woff2'),
  url('../fonts/font-awesome/fa-solid-900.ttf') format('ttf');
  font-weight: 400;
  font-display: swap;
}

@mixin font-awesome {
  font-family: 'Font Awesome 6 Free', sans-serif;
  font-style: normal;
}

@mixin font-roboto {
  font-family: 'Roboto', sans-serif;
}

@mixin font-inter {
  font-family: 'Inter', sans-serif;
}