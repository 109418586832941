.product-counter {
  input[type="number"] {
    -webkit-appearance: textfield;

    &:focus-visible {
      outline: rgba($color-blue, .6) auto 1px;
    }
  }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    appearance: none;
  }

  .number-input {
    border: 1px solid $color-light-grey;
    border-radius: 4px;
    display: inline-flex;
  }

  .number-input button {
    @include font-roboto;
    font-weight: 400;
    color: $color-middle-grey;
    outline: none;
    background-color: transparent;
    border: none;
    align-items: center;
    justify-content: center;
    width: rem(36px);
    height: rem(44px);
    cursor: pointer;
    margin: 0;
    position: relative;
    border: 0;
  }

  .number-input input[type=number] {
    @include font-roboto;
    font-weight: 400;
    @include font-size(16px);
    color: $color-dark-blue;
    line-height: 20px;
    width: rem(36px);
    //max-width: rem(52px);
    padding: rem(8px);
    border: 0;
    height: rem(44px);
    text-align: center;
  }
}