.s-content {

}

.s-content--sidebar {

}

.container{
    max-width: 1156px !important;
}

.featured-banner {
    width: 100%;
    height: auto;
    padding: 0;
    margin: 0 0 rem(30px);
    min-height: 350px;
    background: url("../images/image7.svg");
    background-size: cover;
    border-radius: rem(10px);

    @include sm(){
        min-height: unset;
    }


    &--pfannenbergBG{
        background: white;
        //height: 356px;
        overflow: hidden;
        border-radius: rem(10px);
        z-index: 0;
        background: url("../images/pfannenberg-bg.svg") 100%;
        background-size: cover;
        position: relative;


        @include xxs(){
            background: url("../images/pfannenberg-sko.svg") 100%;
            background-position: 30% 264px;
            background-size: 627px;
            background-repeat: no-repeat;
        }


        .pfannenbergBG--topbuble{
            display: none;
            @include sm(){
                display: unset;
                position: absolute;
                width: 206px;
                height: 206px;
                left: -107px;
                bottom: 468px;

                background: #6AD2FF;
                filter: blur(200px);
            }
        }
        .pfannenbergBG--botbuble{
            display: none;

            @include sm(){
                display: unset;
                position: absolute;
                width: 206px;
                height: 206px;
                right: 7px;
                bottom: -58px;
                background: #009FE3;
                filter: blur(200px);
            }
        }

        .banner {

            margin: 0 auto;
            z-index: 0;
            display: flex;
            flex-direction: row;

            @include sm(){
                flex-direction: column;
            }
        }
        .banner-content {
            width: 100%;
            height: 100%;
            padding: 42px 0 40px 40px;
            color: $color-dark-blue;

            @include sm(){
                padding: rem(24px);
            }

            img{
                padding: 0;
                margin: 0;
                width: 215px;
                height: 48px;
            }

            h2{
                letter-spacing: -0.02em;
                max-width: 509px;
            }

            p {
                @include font-size(16px);
                line-height: 24px;
                margin-top: rem(12px);
                margin-bottom: rem(20px);
            }

            .btn{
                padding: 10px 12px;
                pointer-events: all;
            }

            .cooling{
                width: 282px;
                height: 359px;
                margin: 6px auto 0 auto;
                display: block;

                @include xs(){
                    width: auto;
                    height: 221px;
                }
            }
        }

    }

    @include md(){
        margin-top: 20px;
    }

    .banner {
        width: 100%;
        height: 100%;
        margin: 0 auto;

    }
    .banner-content {
        width: 100%;
        height: 100%;
        padding: rem(48px);
        color: #fff;

        @include md(){
            top: 0;
            transform: translateY(0);
        }

        @include sm(){
            padding: rem(24px);
        }

        h6{
            margin-top: 50px;
            @include sm(){
                margin-top: 26px;
            }
        }

        h2{
            margin-top: rem(4px);
        }

        p {
            @include font-size(16px);
            line-height: 24px;
            margin-top: rem(12px);
            margin-bottom: rem(20px);
        }

        .btn {
            @include font-size(16px);
            line-height: 24px;
            border: 1px solid #fff;
            color: #fff;
            border-radius: 4px;
            padding: rem(12px) rem(16px);
            pointer-events: all;
            &:hover {
                background-color: rgba(250,250,250,0.2);
            }
        }
    }

}