.page--cart {

}

@import "steps-nav";
@import "cart-products";
@import "cart-delivery";
@import "cart-final";



.cart--box-shadow {
  border-radius: 4px;
  box-shadow: 0 4px 10px rgba(#000, 0.12);
  padding: rem(24px);
}

.subtotal-notax{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.subtotal-tax{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.subtotal-total{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.cart-steps-nav-bottom {
  .col-sm-6 {

    &:nth-child(1){
      @include xs{
        text-align: left;
      }
    }

    &:nth-child(2){
      text-align: right;
      @include xs{
        margin-top: rem(12px);
        text-align: left;
      }
    }

    @include xs {
      text-align: center;
    }
  }
}