
.banner-content-info{
  display: flex;

  .banner-content-left{
    padding: 48px 40px;

    @include sm(){
     // padding: 24px 12px;
    }
  }
  .banner-content-right{
    padding: 38px 0 0 40px;

    img{
      width: 291px;
      height: 212px;
      margin-top: 88px;

    }
  }

}

/* na tohle asi solo componentu */
.info-panels-wrapper{

  .info-panel{
    padding: 24px;
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    margin-bottom: 20px;

    &.br-8{
      border-radius: 8px;
    }
  }

}

.servis-text-row{
  display: flex;
  flex-direction: column;

  h3{
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    letter-spacing: -0.02em;
    color: $color-dark-blue;
    flex: none;
    order: 0;
    flex-grow: 0;
  }

  p{
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $color-grey;
    flex: none;
    order: 1;
    flex-grow: 0;
  }


  &--w-image{
    padding: 40px 0 0 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @include sm(){
     padding: 20px 0 0 0;

    }

    &-left{
      width: 100%;
      max-width: 240px;
      display: flex;
      align-items: center;
      img{
        width: auto;
        max-width: 240px;
        height: auto;
        max-height: 192px;
        margin: 0 auto;
        display: block;

        @include sm(){
          width: 50%;
          margin: 0 0 16px 0;

        }
      }
    }

    &-right{
      width: 100%;
      max-width: 598px;
      padding-left: 24px;

      @include sm(){

        padding-left: 0;

      }

      h3{
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: -0.02em;
        color: $color-dark-blue;
        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;
      }

      p{
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: $color-grey;
        flex: none;
        order: 1;
        align-self: stretch;
        flex-grow: 0;
      }

      ul{
        padding-left: 20px;

        li{
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: $color-grey;

          &::marker{
            font-size: 14px;
          }
        }
      }
    }
  }
}

.vision-partner{
  padding: 20px 40px;
  display: flex;
  flex-direction: row;

  .vision-partner-left{
    width: 100%;
    max-width: 488px;

    p{
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      letter-spacing: -0.02em;
      color: $color-dark-blue;
      flex: none;
      order: 0;
      align-self: stretch;
      flex-grow: 0;
    }

    ul{
      padding-left: 20px;

      li{
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: $color-grey;
        padding-bottom: 20px;

        &::marker{
          font-size: 14px;
        }
      }
    }

  }

  .vision-partner-right{
    width: 100%;
    max-width: 259px;
    padding: 108px 0;

    img{
      width: 100%;
      max-width: 200px;
      margin: 0 auto;
    }
  }
}

.tech-support{
  display: flex;
  flex-direction: column;

  .servis-panel-row{


    .servis-panel-title{
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      letter-spacing: -0.02em;
      color: $color-dark-blue;
      flex: none;
      order: 0;
      align-self: stretch;
      flex-grow: 0;


      &-small{
        font-size: 20px;
        line-height: 28px;
        font-weight: 700;
        color: $color-dark-blue;
        flex: none;
        order: 0;
        align-self: stretch;
        flex-grow: 0;
      }
    }

    .servis-panel-text{
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: $color-grey;
    }

    .servis-panel-contacts-row{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      vertical-align: middle;
      align-items: baseline;

      @include sm(){
        flex-direction: column;
      }

      .btn{
        @include sm(){

          margin-top: 16px;
        }
      }
    }
    .servis-panel-texts-row{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      vertical-align: middle;
      align-items: center;

    }
  }
}

.servis-panel-contacts{
  vertical-align: middle;
  display: flex;
  height: 100%;

  @include sm(){
    flex-direction: column;
  }

  a{
    color: $color-primary;
    text-decoration: none;
    vertical-align: middle;
    display: flex;
    padding-right: 20px;

    @include sm(){
      padding-right: 0;
      margin-top: 16px;
    }

    i{
      padding-right: 8px;
    }
  }


}

.servis-panel-materials-info{
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

  .servis-panel-materials-title{
    font-style: normal;
    font-weight: 700;
    font-size: rem(16px);
    line-height: 24px;
    color: $color-dark-blue;
    flex: none;
    order: 0;
    flex-grow: 0;
    padding: 0;
    margin: 0;
  }

  .servis-panel-materials-text{
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: $color-grey;
    flex: none;
    order: 1;
    flex-grow: 0;
    padding: 0;
    margin: 0;
  }
}

.servis-prod-tech-row{

  h4{
    @include font-roboto();
    font-style: normal;
    font-weight: 700;
    font-size: rem(24px);
    line-height: 32px;
    letter-spacing: -0.02em;
    color: $color-dark-blue;
    flex: none;
    order: 0;
    flex-grow: 0;
  }

  ul{

    padding-left: 1.7rem;


    li{

      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: $color-grey;
      flex: none;
      order: 1;
      align-self: stretch;

      &::marker{
        font-size: 14px;
      }
    }
  }

  .servis-prod-tech{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @include sm(){
      flex-direction: column;
    }

    &-list{


      &--title{
        padding: 0;
        margin: 0;
        @include font-roboto();
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: $color-dark-blue;
        flex: none;
        order: 0;
        flex-grow: 0;
      }

      &--file-info{
        padding: 0;
        margin: 0;
        @include font-roboto();
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: $color-grey;
        flex: none;
        order: 1;
        flex-grow: 0;
      }

    }

    .btn{
      @include sm(){
        margin-top: 16px;
      }
    }
  }

}

.servis-printer{

  &--intro{
    h3{
      color: $color-dark-blue;
    }

    p{
      color: $color-grey;

    }
  }

  &--subsection{
    color: $color-dark-blue;


  }

  &--2-cols{
    display: flex;
    flex-direction: row;
    justify-content: start;
    flex-wrap: wrap;
    align-items: center;


    &--col-2{
      padding-left: 20px;
      h3{
        color:$color-dark-blue;

      }

      h4{
        color:$color-dark-blue;
     }

      ul{
        padding-left: 1.7rem;
        li{
          color: $color-grey;

          &::marker{
            font-size: 13px;
          }
        }
      }
    }
  }
}